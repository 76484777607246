<template>
  <div class="container">
    <div class="calculator">
      <div class="top">
        <span id="result">
          {{ result }}
        </span>
      </div>
      <div class="bottom">
        <div class="row">
          <div
            @mousedown="click($event.target)"
            @click="calculate($event.target.textContent)"
            @keydown="hello()"
            @mouseup="click2($event.target)"
            class="item"
            id="+"
          >
            +
          </div>
          <div
            @mousedown="click($event.target)"
            @click="calculate($event.target.textContent)"
            @keydown="hello()"
            @mouseup="click2($event.target)"
            class="item"
            id="-"
          >
            -
          </div>
          <div
            @mousedown="click($event.target)"
            @click="calculate($event.target.textContent)"
            @keydown="hello()"
            @mouseup="click2($event.target)"
            class="item"
            id="*"
          >
            *
          </div>
          <div
            @mousedown="click($event.target)"
            @click="calculate($event.target.textContent)"
            @keydown="hello()"
            @mouseup="click2($event.target)"
            class="item"
            id="/"
          >
            /
          </div>
        </div>
        <div class="row-2">
          <div class="numbers">
            <div class="row">
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="7"
              >
                7
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="8"
              >
                8
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="9"
              >
                9
              </div>
            </div>
            <div class="row">
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="4"
              >
                4
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="5"
              >
                5
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="6"
              >
                6
              </div>
            </div>
            <div class="row">
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="1"
              >
                1
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="2"
              >
                2
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="3"
              >
                3
              </div>
            </div>
            <div class="row">
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="."
              >
                .
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="0"
              >
                0
              </div>
              <div
                @mousedown="click($event.target)"
                @click="calculate($event.target.textContent)"
                @keydown="hello()"
                @mouseup="click2($event.target)"
                class="item"
                id="c"
                style="background-color: rgb(255, 77, 77); color: white"
              >
                c
              </div>
            </div>
          </div>
          <div
            @click="calculate($event.target.textContent)"
            @keydown="hello()"
            class="item enter"
            id="Enter"
          >
            =
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      result: "",
    };
  },
  methods: {
    click(el) {
      el.classList.add("focus");
    },
    click2(el) {
      setTimeout(() => {
        el.classList.remove("focus");
      }, 100);
    },
    calculate(x) {
      if (x.trim() === "=") {
        this.result = `${eval(this.result)}`;
        return;
      }
      if (x.trim() === "c") {
        this.result = "";
        return;
      }
      if (
        this.result.endsWith("+") ||
        this.result.endsWith("-") ||
        this.result.endsWith("/") ||
        this.result.endsWith("*")
      ) {
        if (x.trim() === "+" || x.trim() === "-" || x.trim() === "/" || x.trim() === "*") {
          this.result = `${this.result.slice(0, this.result.length - 1)}${x.trim()}`;
          return;
        }
      }
      this.result += x.trim();
    },
  },
  mounted() {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@font-face {
  font-family: "digi";
  src: url("./digital-7.regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculator {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 242, 242);
  width: 320px;
  height: 440px;
  box-shadow: 2px 2px 2px rgba(46, 46, 46, 0.276);
  border-radius: 10px;
  padding: 3px;
}

.top {
  height: 120px;
  width: 100%;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 20px;
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: end;
  border-radius: 10px;
  color: white;
  background-color: rgb(0, 0, 0);
  box-shadow: inset 57px 63px 211px -20px rgba(255, 255, 255, 0.5);
}

#result {
  text-overflow: ellipsis;
  font-size: 23pt;
  font-family: Arial;
  overflow-x: hidden;
  user-select: none;
  max-width: 300px;
}

.bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bottom .row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
}

.numbers .item,
.row .item {
  display: flex;
  flex-grow: 1;
  margin: 3px;
  font-size: 15pt;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
  box-shadow: 0px 2px 0.5px rgba(46, 46, 46, 0.276);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transition: 0.3s;
}

.numbers .item:hover,
.row .item:hover {
  background-color: rgb(255, 255, 255);
}

.numbers .focus,
.row .focus {
  box-shadow: none;
  transform: translateY(2px);
}

.row-2 {
  display: flex;
  flex-grow: 4;
}

.numbers {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}

.numbers .row {
  flex-grow: 1;
}

.row-2 .enter {
  user-select: none;
  display: flex;
  flex-grow: 0.8;
  background-color: rgb(11, 112, 194);
  margin: 4px;
  border-radius: 5px;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 22pt;
  cursor: pointer;
  transition: 0.3s;
}
.row-2 .enter:hover {
  background-color: rgb(9, 102, 178);
}
</style>
